import { Injectable, computed, effect, signal } from '@angular/core';
import { UserSettingsService } from '@app/services/userSettings';
import { Chart } from 'chart.js';
import { Subject } from 'rxjs';

export interface AppConfig {
    inputStyle: 'outlined' | 'filled';
    colorScheme: 'light' | 'dark';
    theme: string;
    ripple: boolean;
    menuMode: 'static' | 'overlay';
    scale: number;
}

interface LayoutState {
    // True to hide the static menu
    staticMenuDesktopInactive: boolean;
    // True to show the temporary overlay menu
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LayoutService {

    private readonly defaultTheme = 'saga-green';
    private readonly darkTheme = 'arya-green';
    
    /**
     * Signal which contains present dark mode preference and fires when user changes
     */
    readonly dark = computed(() => this.config().colorScheme == 'dark');
    
    /**
     * Signal will emit when the app settings are changed by the user
     */
    config = signal<AppConfig>({
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'static',
        colorScheme: 'light',
        theme: this.defaultTheme,
        scale: 14,
    });

    state = signal<LayoutState>({
        staticMenuDesktopInactive: false,
        overlayMenuActive: true,
        profileSidebarVisible: false,
        configSidebarVisible: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
    });

    private overlayOpen = new Subject<any>();

    /**
     * Text colors used in light and dark modes in ChartJS axis
     */
    private chartColors = {
        light : 'black',
        dark : 'white'
    }

    overlayOpen$ = this.overlayOpen.asObservable();

    constructor(
        private userSettings : UserSettingsService
    ) {
        effect(() => {
            const config = this.config();
            this.changeScale(config.scale);
            this.changeThemes(config.theme);
            this.userSettings.darkMode = (config.colorScheme == 'dark');
        });

        this.state.update( s =>  ({...s, staticMenuDesktopInactive: !this.userSettings.staticMenuVisible}));
        // Load last dark mode from local storage
        const isDarkMode = this.config().colorScheme == 'dark';
        if(this.userSettings.darkMode && !isDarkMode){
            this.toggleDarkMode();
        }
    }

    /**
     * Toggle between dark and light themes
     */
    toggleDarkMode() {

        this.config.update( c => ({...c,  
            colorScheme : (c.colorScheme == 'light') ? 'dark' : 'light',
            theme : (c.colorScheme == 'light') ? this.darkTheme : this.defaultTheme
        }));
    }

    hideLeftMenu(){
        this.onMenuToggle();
    }

    onMenuToggle() {
        
        if (this.isOverlay()) {
            this.state.update( s => ({...s, overlayMenuActive: !s.overlayMenuActive}));
            if (this.state().overlayMenuActive) {
                this.overlayOpen.next(null);
            }
        }

        if (this.isDesktop()) {
            // First toggle the active state
            this.state.update( s => ({...s, staticMenuDesktopInactive: !s.staticMenuDesktopInactive }));
            // Save the inverse of hte state (settings state is positive assertion)
            this.userSettings.staticMenuVisible = !this.state().staticMenuDesktopInactive;
        } else {
            this.state.update( s => ({...s, staticMenuMobileActive: !s.staticMenuMobileActive}));

            if (this.state().staticMenuMobileActive) {
                this.overlayOpen.next(null);
            }
        }
    }

    showProfileSidebar() {
        this.state.update( s=> ({...s, profileSidebarVisible: !s.profileSidebarVisible}));
        if (this.state().profileSidebarVisible) {
            this.overlayOpen.next(null);
        }
    }

    showConfigSidebar() {
        this.state.update(s => ({...s, configSidebarVisible: true}));
    }

    isOverlay() {
        return this.config().menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return !this.isDesktop();
    }

    // Function to change the theme
    changeThemes(newTheme: string): void {
        // You can also update the theme dynamically in the DOM if needed
        const themeLink = document.getElementById('theme-css');
        if (themeLink) {
            themeLink.setAttribute('href', `assets/layout/styles/theme/${newTheme}/theme.css`);
        }
    }

    // changeTheme() {
    //     const config = this.config();
    //     const themeLink = <HTMLLinkElement>document.getElementById('theme-css');
    //     const themeLinkHref = themeLink.getAttribute('href')!;
    //     const newHref = themeLinkHref
    //         .split('/')
    //         .map((el) =>
    //             el == this._config.theme
    //                 ? (el = config.theme)
    //                 : el == `theme-${this._config.colorScheme}`
    //                 ? (el = `theme-${config.colorScheme}`)
    //                 : el
    //         )
    //         .join('/');

    //     this.replaceThemeLink(newHref);
    // }
    replaceThemeLink(href: string) {
        const id = 'theme-css';
        let themeLink = <HTMLLinkElement>document.getElementById(id);
        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(
            cloneLinkElement,
            themeLink.nextSibling
        );
        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }

    changeScale(value: number) {
        document.documentElement.style.fontSize = `${value}px`;
    }

   

    /**
    * Utility function to the set the legend and X / Y axis tick colors on the passed Chart object
    */
    setChartColors(chart:Chart | null, darkMode: boolean){
        const legendColor = darkMode ? this.chartColors.dark : this.chartColors.light;
        if(chart){
            if(chart.options.plugins?.legend?.labels){
                chart.options.plugins.legend.labels.color = legendColor;
            }
            if(chart.options.scales?.['x']?.ticks?.color){
                chart.options.scales['x'].ticks.color = legendColor;
            }
            if(chart.options.scales?.['y']?.ticks?.color){
                chart.options.scales['y'].ticks.color = legendColor;
            }
            chart.update();
        }
    }
}
